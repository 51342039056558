import React, { useCallback, useEffect, useState } from 'react';
import Popover from '../popover.component';
import { Textbox, TextOptions } from 'fabric/fabric-impl';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useCanvasHistory } from '../../contexts/canvas-history.context';

import { Properties, StyleButton } from './object-toolbar.style';
import { IBasicEditorProps } from '../../interfaces/editor.interface';
import {
  changeFontSize,
  changeFontStyle,
  changeFontWeight,
  changeTextDecoration,
  transformText,
  focusOnTextBox,
} from '../../utils/editor.utils';
import FontSizeTool from './font-size.tool.component';
import FontsList from './fonts-list.component';
import { getActionKey } from '../../utils/shared.utils';

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  position: relative;
  background: ${(p) => p.theme.white};
  padding-top: 1px;
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 9px;
    display: block;
    height: 1px;
    width: calc(100% - 18px);
    background: ${(p) => p.theme.lightGray2};
  }
`;

const ButtonTitle = styled.p`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0;
  margin: 0;
`;

type TextStylingProperties = {
  underline?: boolean;
  linethrough?: boolean;
  bold?: boolean;
  italic?: boolean;
  uppercase?: boolean;
  lowercase?: boolean;
};

interface IProps extends IBasicEditorProps {
  selectionStyles?: TextOptions[];
}

const TextStylingTool = ({ activeObject, editor, selectionStyles }: IProps) => {
  const { t } = useTranslation();
  const { historySaveAction } = useCanvasHistory();
  const textBox = activeObject as Textbox;

  const [fontSize, setFontSize] = useState('');
  const [popoverActive, setPopoverActive] = useState<boolean>(false);
  const [activeFont, setActiveFont] = useState<{ style: string; weight: string | number }>();

  const defaultTextBoxFontStyles = {
    style: textBox.get('fontFamily') || '',
    weight: textBox.get('fontWeight') || '',
  };

  const [textProperties, setTextProperties] = useState<TextStylingProperties>({
    bold: !!JSON.parse(textBox.globalCompositeOperation || '{}').bold,
    underline: !!JSON.parse(textBox.globalCompositeOperation || '{}').underline,
    linethrough: !!JSON.parse(textBox.globalCompositeOperation || '{}').linethrough,
    italic: !!JSON.parse(textBox.globalCompositeOperation || '{}').italic,
    uppercase: !!JSON.parse(textBox.globalCompositeOperation || '{}').uppercase,
    lowercase: !!JSON.parse(textBox.globalCompositeOperation || '{}').lowercase,
  });

  useEffect(() => {
    if (selectionStyles || textBox) {
      const globalCompositeOperation = JSON.parse(textBox.globalCompositeOperation || '{}');
      const { selectionStart, selectionEnd } = textBox;

      const checkAllBold = () => {
        if (!textBox.styles[0]) return null;
        for (let i = 0; i < Object.values(textBox.styles[0]).length; i++) {
          if (textBox.styles[0][i] >= '700') {
            return true;
          }
        }
        return false;
      };

      const isAllTextUppercase = (texts: string[]) =>
        texts.every((text) => text === text.toUpperCase());

      const allTextUppercase = isAllTextUppercase(textBox._text);

      if (
        allTextUppercase &&
        (globalCompositeOperation.uppercase == undefined ||
          globalCompositeOperation.uppercase == null)
      ) {
        handleTransFormText('uppercase')();
        handleTextPropertyChange('uppercase', true);
      }

      // TODO: CHECK TO 800 AND 900 FW
      if (checkAllBold()) {
        setTimeout(() => {
          textBox.set('fontWeight', 'bold');
          handleTextPropertyChange('bold', true);
        }, 0);
      }

      const selectionFontWeightIsBold = textBox
        .getSelectionStyles(selectionStart, selectionEnd)
        .every((style) => style.fontWeight === 'bold' || style.fontWeight >= 700);

      const isBold = selectionFontWeightIsBold;

      const newProperties = {
        bold: isBold,
        underline: globalCompositeOperation.underline,
        linethrough: globalCompositeOperation.linethrough,
        italic: globalCompositeOperation.italic,
        uppercase:
          globalCompositeOperation.uppercase === undefined ||
          globalCompositeOperation.uppercase === null
            ? allTextUppercase
            : globalCompositeOperation.uppercase,
        lowercase: globalCompositeOperation.lowercase,
      };

      setTextProperties(newProperties);
      updateGlobalCompositeOperation(newProperties);
      editor.canvas.renderAll();
    }
  }, []);

  useEffect(() => {
    if (selectionStyles || textBox) {
      const globalCompositeOperation = JSON.parse(textBox.globalCompositeOperation || '{}');
      const newProperties = {
        bold: globalCompositeOperation.bold,
        underline: globalCompositeOperation.underline,
        linethrough: globalCompositeOperation.linethrough,
        italic: globalCompositeOperation.italic,
        uppercase: globalCompositeOperation.uppercase,
        lowercase: globalCompositeOperation.lowercase,
      };
      setTextProperties(newProperties);
      updateGlobalCompositeOperation(newProperties);
    }
  }, [selectionStyles, textBox]);

  const updateGlobalCompositeOperation = (properties: TextStylingProperties) => {
    const currentOperation = JSON.parse(textBox.globalCompositeOperation || '{}');
    textBox.set('globalCompositeOperation', JSON.stringify({ ...currentOperation, ...properties }));
    editor.canvas.renderAll();
  };

  const handleAccordionToggle = () => {
    setPopoverActive((prev) => !prev);
  };

  useEffect(() => {
    getFontSize();
  }, [activeObject]);

  const handleTextPropertyChange = (key: keyof TextStylingProperties, value: boolean) => {
    setTextProperties((prev) => {
      const newProperties = { ...prev, [key]: value };
      updateGlobalCompositeOperation(newProperties);
      applyTextPropertiesToTextbox(newProperties);
      return newProperties;
    });
  };

  const applyTextPropertiesToTextbox = (properties: TextStylingProperties) => {
    if (textBox) {
      textBox.set({
        fontWeight: properties.bold ? 'bold' : 'normal',
        underline: properties.underline,
        linethrough: properties.linethrough,
        fontStyle: properties.italic ? 'italic' : 'normal',
      });
      editor.canvas.renderAll();
    }
  };

  const handleBoldClick = () => {
    if (activeObject && activeObject.type === 'textbox') {
      const text = activeObject as Textbox;
      const { selectionStart, selectionEnd } = text;

      if (selectionStart !== 0) {
        const selectionFontWeightIsBold = text
          .getSelectionStyles(selectionStart, selectionEnd)
          .every((style) => style.fontWeight === 'bold' || style.fontWeight >= 700);

        if (selectionFontWeightIsBold) {
          text.setSelectionStyles({ fontWeight: 'normal' }, selectionStart, selectionEnd);
          handleTextPropertyChange('bold', false);
        } else {
          text.setSelectionStyles({ fontWeight: 'bold' }, selectionStart, selectionEnd);
          handleTextPropertyChange('bold', true);
        }
      } else {
        const isBold = text
          .getSelectionStyles(selectionStart, selectionEnd)
          .every((style) => style.fontWeight === 'bold' || style.fontWeight >= 700);

        if (isBold) {
          changeFontWeight(activeObject, editor.canvas, 400).then(() => {
            historySaveAction(editor);
          });
          handleTextPropertyChange('bold', false);
        } else {
          changeFontWeight(activeObject, editor.canvas, 700).then(() => {
            historySaveAction(editor);
          });
          handleTextPropertyChange('bold', true);
        }
      }

      editor.canvas.renderAll();
      focusOnTextBox(text);
    }
  };

  const handleItalicClick = () => {
    if (activeObject && activeObject.type === 'textbox') {
      const text = activeObject as Textbox;
      const { selectionStart, selectionEnd } = text;

      if (selectionStart !== selectionEnd) {
        const selectionFontStyleIsItalic = text
          .getSelectionStyles(selectionStart, selectionEnd)
          .every((style) => style.fontStyle === 'italic');

        if (selectionFontStyleIsItalic) {
          text.setSelectionStyles({ fontStyle: 'normal' }, selectionStart, selectionEnd);
          handleTextPropertyChange('italic', false);
        } else {
          text.setSelectionStyles({ fontStyle: 'italic' }, selectionStart, selectionEnd);
          handleTextPropertyChange('italic', true);
        }
      } else {
        const isItalic = text.fontStyle && text.fontStyle === 'italic';

        if (isItalic) {
          changeFontStyle(activeObject, editor.canvas, 'normal').then(() => {
            historySaveAction(editor);
          });
          handleTextPropertyChange('italic', false);
        } else {
          changeFontStyle(activeObject, editor.canvas, 'italic').then(() => {
            historySaveAction(editor);
          });
          handleTextPropertyChange('italic', true);
        }
      }

      editor.canvas.renderAll();
      focusOnTextBox(text);
    }
  };

  const handleTextDecoration = (key: 'underline' | 'linethrough') => () => {
    if (activeObject && activeObject.type === 'textbox') {
      const text = activeObject as Textbox;
      const { selectionStart, selectionEnd } = text;

      const styles = JSON.parse(text.globalCompositeOperation || '{}');
      const isStyled = styles[key];

      if (!isStyled) {
        text.set(
          'globalCompositeOperation',
          JSON.stringify({
            ...JSON.parse(text.globalCompositeOperation || '{}'),
            ...{ [key]: true },
          }),
        );
      } else {
        delete styles[key];
        text.set('globalCompositeOperation', JSON.stringify(styles));
      }

      if (selectionStart != 0) {
        const selectionFontStyled = text
          .getSelectionStyles(selectionStart, selectionEnd)
          .every((style) => style[key]);

        text.setSelectionStyles({ [key]: !selectionFontStyled }, selectionStart, selectionEnd);
        handleTextPropertyChange(key, !isStyled);
      } else {
        changeTextDecoration(activeObject, editor.canvas, key, !isStyled).then(() => {
          historySaveAction(editor);
        });
        handleTextPropertyChange(key, !isStyled);
      }

      editor.canvas.renderAll();
      focusOnTextBox(text);
    }
  };

  const handleTransFormText = (value: 'uppercase' | 'lowercase', caseValue?: boolean) => () => {
    if (activeObject) {
      const isUpperCase = !!textProperties.uppercase;
      const isLowerCase = !!textProperties.lowercase;

      editor.canvas.discardActiveObject();
      editor.canvas.setActiveObject(activeObject);

      if (value === 'uppercase') {
        handleTextPropertyChange('uppercase', caseValue || !isUpperCase);
        isLowerCase && !isUpperCase && handleTextPropertyChange('lowercase', false);
        if (!isUpperCase) {
          transformText(activeObject, editor.canvas, value).then(() => {
            historySaveAction(editor);
          });
        } else {
          transformText(activeObject, editor.canvas, undefined).then(() => {
            historySaveAction(editor);
          });
        }
      } else {
        handleTextPropertyChange('lowercase', !isLowerCase);
        isUpperCase && !isLowerCase && handleTextPropertyChange('uppercase', false);
        if (!isLowerCase) {
          transformText(activeObject, editor.canvas, value).then(() => {
            historySaveAction(editor);
          });
        } else {
          transformText(activeObject, editor.canvas, undefined).then(() => {
            historySaveAction(editor);
          });
        }
      }
      !caseValue && focusOnTextBox(activeObject as Textbox);
    }
  };

  const getFontSize = () => {
    if (activeObject && activeObject.type === 'textbox') {
      const textbox = activeObject as Textbox;
      setFontSize(`${textbox.get('fontSize')}`);
    }
  };

  const handleFontSizeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(e.target.value, 10);
    if (isNaN(value)) {
      setFontSize('0');
      activeObject &&
        changeFontSize(activeObject, editor.canvas, 12).then(() => {
          historySaveAction(editor);
        });
    } else {
      setFontSize(`${value}`);
      activeObject &&
        changeFontSize(activeObject, editor.canvas, parseInt(e.target.value, 10)).then(() => {
          historySaveAction(editor);
        });
    }
    focusOnTextBox(activeObject as Textbox);
  };

  const getFontFamily = () => {
    if (activeObject && activeObject.type === 'textbox') {
      const textbox = activeObject as Textbox;
      return textbox.get('fontFamily');
    }
    return 'Inter';
  };

  const handleFontChoose = (font: string, value: number | string) => {
    setActiveFont({
      style: font,
      weight: value,
    });
  };

  const handleKeyboardEvents = useCallback(
    (e: KeyboardEvent) => {
      const actionKey = getActionKey();
      switch (e.code) {
        case 'KeyB': {
          if (e[actionKey]) {
            e.preventDefault();
            handleBoldClick();
          }
          break;
        }
        case 'KeyI': {
          if (e[actionKey]) {
            e.preventDefault();
            handleItalicClick();
          }
          break;
        }
        case 'KeyU': {
          if (e[actionKey]) {
            e.preventDefault();
            handleTextDecoration('underline')();
          }
          break;
        }
      }
    },
    [activeObject],
  );

  useEffect(() => {
    document.addEventListener('keydown', handleKeyboardEvents, false);
    return () => {
      document.removeEventListener('keydown', handleKeyboardEvents, false);
    };
  }, [handleKeyboardEvents]);

  useEffect(() => {
    setActiveFont({
      style: textBox.get('fontFamily') || '',
      weight: textBox.get('fontWeight') || '',
    });
  }, [activeObject]);

  return (
    <Properties>
      <Popover
        position={'left'}
        relativeToParent={true}
        setIsActive={handleAccordionToggle}
        content={
          <FontsList
            editor={editor}
            activeObject={activeObject}
            active={activeFont || defaultTextBoxFontStyles}
            onChoose={(font, value) => handleFontChoose(font, value)}
          />
        }
        closeOnClick={false}
      >
        <StyleButton data-tooltip={t('tooltip.textFont') as string} data-tooltip-position="left">
          <ButtonTitle>{getFontFamily()}</ButtonTitle>
        </StyleButton>
      </Popover>
      <FontSizeTool
        activeObject={activeObject}
        editor={editor}
        onChange={handleFontSizeChange}
        value={fontSize}
        type={'number'}
        min={12}
        max={60}
      />
      <Grid>
        <StyleButton
          data-tooltip={t('tooltip.bold') as string}
          data-tooltip-position="left"
          onClick={handleBoldClick}
          active={textProperties.bold}
        >
          <span className={'icon-bold'} />
        </StyleButton>
        <StyleButton
          data-tooltip={t('tooltip.italic') as string}
          data-tooltip-position="right"
          onClick={handleItalicClick}
          active={textProperties.italic}
        >
          <span className={'icon-italic'} />
        </StyleButton>
        <StyleButton
          data-tooltip={t('tooltip.underline') as string}
          data-tooltip-position="left"
          onClick={handleTextDecoration('underline')}
          active={textProperties.underline}
        >
          <span className={'icon-underline'} />
        </StyleButton>
        <StyleButton
          data-tooltip={t('tooltip.linethrough') as string}
          data-tooltip-position="bottom"
          onClick={handleTextDecoration('linethrough')}
          active={textProperties.linethrough}
        >
          <span className={'icon-strikethrough'} />
        </StyleButton>
      </Grid>
      <Grid>
        <StyleButton
          data-tooltip={t('tooltip.uppercase') as string}
          data-tooltip-position="left"
          onClick={handleTransFormText('uppercase')}
          active={textProperties.uppercase}
        >
          AB
        </StyleButton>
        <StyleButton
          data-tooltip={t('tooltip.lowercase') as string}
          data-tooltip-position="bottom"
          onClick={handleTransFormText('lowercase')}
          active={textProperties.lowercase}
        >
          ab
        </StyleButton>
      </Grid>
    </Properties>
  );
};

export default TextStylingTool;
